import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'vant/lib/button/style'
import mixin from "@/common/wxshare"

import 'vant/lib/index.css';
import { Checkbox, CheckboxGroup, Button, RadioGroup, Radio, Field, Toast, List, NavBar, Overlay, Icon, Uploader, Picker, ActionSheet, DropdownMenu, DropdownItem ,Tabbar, TabbarItem ,Swipe, SwipeItem ,Grid, GridItem, Loading, Image as VanImage, Lazyload,Empty,Popup,Collapse, CollapseItem} from 'vant'

Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Button)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Field)
Vue.use(Toast)
Vue.use(List)
Vue.use(NavBar)
Vue.use(Overlay)
Vue.use(Icon)
Vue.use(Uploader)
Vue.use(Picker)
Vue.use(ActionSheet)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Loading)
Vue.use(VanImage)
Vue.use(Lazyload)
Vue.use(Empty)
Vue.use(Popup)
Vue.use(Collapse)
Vue.use(CollapseItem)

Vue.use(mixin)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  console.log({ to, from })
  /*路由发生改变修改页面的title */
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "乐腾出海"
  }
  if (to.meta.index !== null) {
    localStorage.setItem("tabIndex", to.meta.index)
  }

  /*判断路由是否需要权限才能进入，即路由拦截 */
  to.meta && (document.title = to.meta.title)

  let routerArr = ['/','/home','/index','/nation','/team','/custom','/nationInfo','/inquiryHome']
  let routerShow = true
  // let routerArr2 = ['/','/home','/index','/nation','/team','/custom','/nationInfo','/inquiryHome','/inquiryList','/inquiryShow']
  // let routerShow2 = true

  //超过三天未访问删除token
  let oldDate = localStorage.getItem('oldDate')
  let newDate = new Date().getTime()
  if (oldDate && newDate - oldDate > 1000 * 60 * 60 * 24 * 3) {
    localStorage.removeItem('wj_token')
  } else if (to.path !== '/' && to.path !== '/home') {
    localStorage.setItem('oldDate', newDate)
  }

  let token = localStorage.getItem('wj_token')
  for(let i=0;i<routerArr.length;i++){
    if(routerArr[i]===to.path){
      routerShow = false
      break;
    }
  }
  if (to.path !== '/login'&& to.path !== '/inquiryLogin' && !token && routerShow) {
    let toRouter = to.path=='/inquiryList'||to.path=='/inquiryShow'?'/inquiryLogin':'/login'
    console.log(toRouter)
    next(toRouter)
  }
  console.log(from.path,to.path)
  //判断是否需要缓存
  // if(to.path == '/index' && from.path =='/nation'){
  //   to.meta.keepAlive = true;  // 让 列表页 缓存，即不刷新
  // }else {
  //   to.meta.keepAlive = false;  // 让 列表页 即不缓存，刷新
  // }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
