import wx from 'weixin-js-sdk'
import axios from "axios"

export default{
    install(Vue) {
        Vue.prototype.shareList = function (imgUrl, link, desc, title) {
            // var url = encodeURIComponent(link)
            var url = link
            // 分享
            axios({
                method:"get",
                url:'http://leifeng.yunxiangqin.cn/api/we_chat/jsconfig',
                params:{url: url},
                header: {
                    'Content-Type':'application/json;charset=UTF-8',
                },
            }).then(function (res) {
                // alert(JSON.stringify(res))
                console.log('公众号js配置',res)
                let res2 = res.data.data.config
                wx.config({
                    debug: false, // true:是调试模式,调试时候弹窗,会打印出日志
                    appId: res2.appId, // 微信appid
                    timestamp: res2.timestamp, // 时间戳
                    nonceStr: res2.nonceStr, // 随机字符串
                    signature: res2.signature, // 签名
                    jsApiList:[
                        'updateTimelineShareData',
                        'updateAppMessageShareData'
                    ]
                    // jsApiList: [
                    //     // 所有要调用的 API 都要加到这个列表中
                    //     'onMenuShareTimeline', // 分享到朋友圈接口
                    //     'onMenuShareAppMessage', //  分享到朋友接口
                    //     'onMenuShareQQ', // 分享到QQ接口
                    //     'onMenuShareWeibo', // 分享到微博接口
                    //     'updateTimelineShareData',
                    //     'updateAppMessageShareData'
                    // ]
                })
                wx.checkJsApi({
                    jsApiList:[
                        'updateTimelineShareData',
                        'updateAppMessageShareData'
                    ],
                    // jsApiList: [
                    //     // 所有要调用的 API 都要加到这个列表中
                    //     'onMenuShareTimeline', // 分享到朋友圈接口
                    //     'onMenuShareAppMessage', //  分享到朋友接口
                    //     'onMenuShareQQ', // 分享到QQ接口
                    //     'onMenuShareWeibo', // 分享到微博接口
                    //     'updateTimelineShareData',
                    //     'updateAppMessageShareData'
                    // ],
                    success: function (res) {
                        // alert("checkJsApi:success");
                    }
                })

                wx.ready(function () {
                    // 微信分享的数据
                    var shareData = {
                        imgUrl: imgUrl, // 分享显示的缩略图地址
                        link: res2.url, // 分享地址
                        desc: desc, // 分享描述
                        title: title, // 分享标题
                        success: function () {
                            // 分享成功可以做相应的数据处理
                            // alert('分享成功')
                            // alert('appId:' + res.appId)
                            // alert('timestamp:' + res.timestamp)
                            // alert('nonceStr:' + res.nonceStr)
                            // alert('signature:' + res.signature)
                        },
                        fail: function () {
                            // alert('调用失败')

                        },
                        complete: function () {
                            // alert('调用结束')

                        }
                    }
                    wx.updateTimelineShareData(shareData)
                    wx.updateAppMessageShareData(shareData)
                    // wx.onMenuShareQQ(shareData)
                    // wx.onMenuShareWeibo(shareData)
                })
                wx.error(function (res) {
                    // config信息验证失败会执行error函数，如签名过期导致验证失败，
                    // 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，
                    // 对于SPA可以在这里更新签名。
                    console.log('分享失败',res)
                    // alert('分享失败',res)

                })
            }, function (res) {
                // alert(res)
                // console.log(parseInt(res.code))
            })
        }
    }
}
