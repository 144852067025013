<template>
  <div class="box">
    <!-- <img class="logo" src="@/assets/logo.jpg" alt="" /> -->
    <div class="banner">
      <img src="@/assets/banner2.jpg" alt="" />
    </div>
      
    <Cell :forbid="forbid" :result="result" />
  </div>
</template>

<script>

import Cell from "../components/cell.vue";
export default {
  components: {
    Cell,
  },
  data() {
    return {
      forbid:false,  //是否禁止输入
      result: [],
    };
  },
  created() {
    
  },
  methods: {
    submit() {
      console.log(this.result);
      this.result = ['a']
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .logo{
    // display: none;
    width: 1.2rem;
  }
  .banner {
    img {
      // display: none;
      width: 100%;
    }
  }
  
}
</style>
