//管理这个项目的所有接口
import axios from "./requset";

//登录
export const Login = (params) => axios.post('/user/login',  params )

// 收集用户问卷信息详情
export const getInfoById = (params) => axios.get('/userInfo/findUserInfoById', { params })

//问卷管理列表
export const getInfoList = (params) => axios.get('/userInfo/getUserInfo', { params })

//问卷提交
export const addUserInfo = (params) => axios.post('/userInfo/addUserInfo', params )


//添加首页banner图
export const addBanner = (params) => axios.post('/carousel/addCarousel', params )

//添加国家
export const addNation = (params) => axios.post('/nation/addNation', params )

//上传移民项目信息
export const addProject = (params) => axios.post('/immigration/addImmigration', params )

//获取所有国家
export const getNation = (params) => axios.get('/nation/findNations', {params} )




// 获取首页banner图
export const getBanner = () => axios.get('/carousel/findCarousel')

// 获取首页国家
// export const getNation = () => axios.get('/nation/findNations')

// 获取移民项目国家
export const getFindImmigrations = () => axios.get('/immigration/findImmigrations')

// 根据ID查询移民项目信息
export const getFindImmigrationsById = (params) => axios.get('/immigration/findImmigrationById',{params})



//提交调查问卷
export const addAndrology = (params) => axios.post('/andrology/addAndrology', params )

//获取调查问卷信息
export const getAndrology = (params) => axios.get('/andrology/AndrologyAll',{params})
