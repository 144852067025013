<template>
  <div class="box">
    <div class="message" v-if="forbid">
      <div>序号：{{ index }}</div>
      <div>提交时间：{{ result.createTime }}</div>
      <div>姓名：{{ result.userName }}</div>
      <div>电话：{{ result.phone }}</div>
    </div>
    <div class="cell" v-for="(item, index) in info" :key="index">
      <div class="title">{{ item.title }}</div>
      <div class="maxnum" v-if="item.maxnum === 2">（最多可选择两个）</div>
      <div class="explain" v-if="item.explain">{{ item.explain }}</div>

      <!-- 多选 -->
      <van-checkbox-group
        :disabled="forbid"
        v-if="item.maxnum > 1"
        v-model="item.result"
        direction="horizontal"
        :max="item.maxnum"
      >
        <van-checkbox
          shape="square"
          v-for="(temp, index2) in item.option"
          :key="index2"
          :name="temp.name"
        >
          <template #icon="props">
            <van-button size="small" v-if="props.checked" color="#5B8CE1">{{
              temp.text
            }}</van-button>
            <van-button size="small" v-else>{{ temp.text }}</van-button>
          </template>
        </van-checkbox>
      </van-checkbox-group>
      <!-- 单选 -->
      <van-radio-group
        :disabled="forbid"
        v-if="item.maxnum === 1"
        v-model="item.result"
      >
        <van-radio
          shape="square"
          v-for="(temp, index2) in item.option"
          :key="index2"
          :name="temp.name"
        >
          <template #icon="props">
            <van-button size="small" v-if="props.checked" color="#5B8CE1">{{
              temp.text
            }}</van-button>
            <van-button size="small" v-else>{{ temp.text }}</van-button>
          </template>
        </van-radio>
      </van-radio-group>
    </div>
    <div class="cell">
      <div class="title">11.请填写您的联系方式</div>
      <div class="explain">(填写准确联系方式使我们的客服更好的联系到您)</div>
      <van-field
        :border="false"
        v-model="fromData.userName"
        :disabled="forbid"
        label="姓名："
        placeholder="请输入姓名"
      />
      <van-field
        v-model="fromData.phone"
        label="联系电话："
        placeholder="请输入联系电话"
      />
    </div>
    <div class="footer" v-if="!forbid">
      <div class="explain">*此问卷系统版权归乐腾出海所有</div>
      <van-button :class="lock ? 'past' : 'nopast'" @click="submit()">{{
        lock ? "已提交" : "提交"
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { addUserInfo } from "../utils/axios/api";
export default {
  props: ["forbid", "result", "index"],
  data() {
    return {
      lock: false,
      results: [],
      //请求数据
      fromData: {
        age: "",
        position: [],
        identity: [],
        property: "",
        intention: "",
        livingDays: "",
        englishLevel: "",
        investmentBudget: "",
        immigrationStatus: [],
        immigrationPlan: "",
        userName: "",
        phone: "",
      },
      info: [
        {
          title: "1.请告诉我们您的年龄？",
          maxnum: 1,
          explain: "",
          option: [
            {
              text: "18岁以下",
              name: "A",
            },
            {
              text: "18-29岁",
              name: "B",
            },
            {
              text: "30-54岁",
              name: "C",
            },
            {
              text: "55岁以上",
              name: "D",
            },
          ],
          result: "",
        },
        {
          title: "2.您目前的职位及身份，哪种描述更为准确?",
          maxnum: 2,
          explain:
            "(部分移民项目对职业或管理经验有要求，告诉我们您的情况有助干更精准的推荐项目)",
          option: [
            {
              text: "企业高管",
              name: "A",
            },
            {
              text: "企业投资人、股东或其他高资产人士",
              name: "B",
            },
            {
              text: "某行业达到专家水平以上的精英人士",
              name: "C",
            },
            {
              text: "学生或子女有教育需求的家长",
              name: "D",
            },
            {
              text: "自雇人员",
              name: "E",
            },
          ],
          result: [],
        },
        {
          title: "3.您更倾向哪种方式来获取海外身份?",
          maxnum: 2,
          explain:
            "(投资移民对由请人个人条件要求比较少，个别国家会在一定的时间内进行返还部分投资额:购买房产可以用干和赁及出售，还有升值空间，相当于“不花钱”附赠一个身份;用过创业的方式移民也可以实现工作、身份子女教育一举多得)",
          option: [
            {
              text: "投资或购房",
              name: "A",
            },
            {
              text: "海外创业",
              name: "B",
            },
            {
              text: "其他",
              name: "C",
            },
          ],
          result: [],
        },
        {
          title: "4.请告诉我们您的家庭净资产大概是多少?",
          maxnum: 1,
          explain: "",
          option: [
            {
              text: "500万元人民币以下",
              name: "A",
            },
            {
              text: "500-1000万人民币",
              name: "B",
            },
            {
              text: "1000-2000万人民币",
              name: "C",
            },
            {
              text: "2000-5000万人民币",
              name: "D",
            },
            {
              text: "5000万人民币以上",
              name: "E",
            },
          ],
          result: "",
        },
        {
          title: "5.您对哪些国家更有意向?",
          maxnum: 1,
          explain: "",
          option: [
            {
              text: "加拿大、澳洲、新西兰、美国传统移民大国",
              name: "A",
            },
            {
              text: "英国、法国、西班牙等欧洲国家",
              name: "B",
            },
            {
              text: "新加坡，马来西亚，日本等亚洲国家",
              name: "C",
            },
            {
              text: "圣基茨、塞浦路斯等投资可获取护照的欧洲或加勒比国家",
              name: "D",
            },
          ],
          result: "",
        },
        {
          title: "6.您能接受在当地的居住天数?",
          maxnum: 1,
          explain: "",
          option: [
            {
              text: "可以接受在当地长期居住(每年30天以上)",
              name: "A",
            },
            {
              text: "只能在当地短暂居住(每年30天以内)",
              name: "B",
            },
          ],
          result: "",
        },
        {
          title: "7.目前您可达到的英语水平？",
          maxnum: 1,
          explain: "",
          option: [
            {
              text: "完全不会",
              name: "A",
            },
            {
              text: "大学英语4级及以下（雅思5分及以下）",
              name: "B",
            },
            {
              text: "大学英语6级及以下（雅思5-6分）",
              name: "C",
            },
            {
              text: "大学英语6级优秀（雅思6.5-7分）",
              name: "D",
            },
            {
              text: "专业英语8级（雅思7分及以上）",
              name: "E",
            },
          ],
          result: "",
        },
        {
          title: "8.您最多可用于移民或投资的预算是多少？",
          maxnum: 1,
          explain: "",
          option: [
            {
              text: "100万人民币以下",
              name: "A",
            },
            {
              text: "100万-250万人民币",
              name: "B",
            },
            {
              text: "250万-500万人民币",
              name: "C",
            },
            {
              text: "500万人民币以上",
              name: "D",
            },
          ],
          result: "",
        },
        {
          title: "9.您最希望获得怎么样的移民身份？",
          maxnum: 2,
          explain:
            "(工作签证一定时期内可以在当地工作生活，获取成本低维持需要一定条件，永久居民可长期或不受限制地在当地工作 生活居住，第二护照可有效转化税务身份，达到全球资产规划的目的。)",
          option: [
            {
              text: "居留签证",
              name: "A",
            },
            {
              text: "永久居住（绿卡）",
              name: "B",
            },
            {
              text: "第二护照",
              name: "C",
            },
            {
              text: "不需要移民身份",
              name: "D",
            },
          ],
          result: [],
        },
        {
          title: "10.您打算何时开始您的移民计划？",
          maxnum: 1,
          explain: "",
          option: [
            {
              text: "半年以内",
              name: "A",
            },
            {
              text: "1-2年",
              name: "B",
            },
            {
              text: "随时都行，对时间没要求",
              name: "C",
            },
          ],
          result: "",
        },
      ],
    };
  },
  methods: {
    submit() {
      // if (this.lock) return this.$toast("问卷提交过了~");
      let info = this.info;
      let fromArr = [];
      let param = new FormData();
      for (let i in this.fromData) {
        console.log(i);
        if (i !== "user_name" && i !== "phone") fromArr.push(i);
      }
      console.log(fromArr);
      if (!this.fromData.phone || !this.fromData.userName)
        return this.$toast("请填写完整信息后提交！");
      for (let i = 0; i < info.length; i++) {
        console.log(typeof info[i].result);
        if (typeof info[i].result === "object") {
          if (!info[i].result.length)
            return this.$toast("请填写完整信息后提交！");
          console.log("===");
        } else {
          if (!info[i].result) return this.$toast("请填写完整信息后提交！");
        }
        for (let k = 0; k < fromArr.length; k++) {
          if (i === k) {
            this.fromData[fromArr[k]] = info[i].result;
            param.append(fromArr[k], info[i].result);
          }
        }
      }
      console.log("fromData:", this.fromData);
      // this.$toast.success('提交成功')
      param.append("userName", this.fromData.userName);
      param.append("phone", this.fromData.phone);
      addUserInfo(param)
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.lock = true;
            this.$toast.success("提交成功");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    result: {
      handler(newVal) {
        console.log("newVal", newVal);
        this.fromData.userName = newVal.userName;
        this.fromData.phone = newVal.phone;

        let arr = [];
        for (let i in this.fromData) {
          if (i !== "user_name" && i !== "phone") arr.push(i);
        }
        for (let k = 0; k < arr.length; k++) {
          this.info[k].result = newVal[arr[k]];
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.message {
  border-bottom: 0.01rem solid #ebedf0;
  padding: 0.12rem 0.16rem 0.12rem;
  div {
    font-size: 0.14rem;
    line-height: 0.3rem;
    font-weight: 800;
    color: #292933;
  }
}
.van-cell {
  // line-height: 0.35rem;
  /deep/.van-field__label {
    text-align: right;
    width: 0.8rem;
    margin-right: 0;
    color: #323233 !important;
  }
  /deep/.van-cell__value {
    border: 1px solid #999;
    width: 1rem;
    margin-right: 0.2rem;
    padding: 0.05rem;
    .van-field__control:disabled {
      color: #323233;
      -webkit-text-fill-color: #292933;
    }
  }
}

.cell {
  border-bottom: 0.01rem solid #ebedf0;
  padding: 0.12rem 0.16rem 0.04rem;
  .title {
    font-size: 0.16rem;
    color: #292933;
    font-weight: 800;
  }
  .maxnum {
    font-size: 0.14rem;
    color: #5c6166;
    padding-top: 0.04rem;
  }
  .explain {
    line-height: 0.24rem;
    font-size: 0.12rem;
    color: #919499;
    padding-top: 0.04rem;
  }

  /deep/.van-radio-group,
  /deep/.van-checkbox-group {
    padding-top: 0.04rem;
    padding-bottom: 0.14rem;
  }
  /deep/.van-checkbox--horizontal{
    margin-right: .1rem;
  }
  .van-checkbox,
  .van-radio {
    padding-top: 0.1rem;
  }
  /deep/.van-radio-group {
    display: flex;
    flex-wrap: wrap;
    .van-radio {
      display: inline-block;
      margin-right: 0.1rem;
    }
  }
  /deep/.van-checkbox__icon,
  /deep/.van-radio__icon {
    height: auto;
    .van-button--default {
      box-sizing: border-box !important;
      border-color: rgb(146, 146, 146);
      .van-button__text {
        font-size: 0.14rem;
        padding: 0.05rem 0;
      }
    }
    .van-button--small {
      height: auto;
    }
  }
}
.footer {
  overflow-x: hidden;
  .explain {
    padding: 0.2rem 0.16rem 0;
    font-size: 0.12rem;
    color: #919499;
  }
  .van-button {
    width: 2.2rem;
    height: 0.38rem;
    text-align: center;
    // line-height: 0.38rem;
    border-radius: 0.08rem;
    color: white;
    margin-left: 50%;
    // margin-bottom: 0.5rem;
    transform: translate(-50%,-80%);
  }
  .past {
    background-color: #adadad;
  }
  .nopast {
    background-color: #5b8ce1;
  }
}
</style>
