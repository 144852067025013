import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/error.vue'),
    meta: {
      index:null,title: '地址错误'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue'),
    meta: {
      index:null,title: '乐腾出海-出国移民问卷调查'
    }
  },
  {
    path: '/infolist',
    name: 'infolist',
    component: () => import(/* webpackChunkName: "about" */ '../views/infoList.vue'),
    meta: {
      index:null,title: '乐腾出海用户信息收集',
      keepAlive: true
    }
  },
  {
    path: '/infoshow',
    name: 'infoshow',
    component: () => import(/* webpackChunkName: "about" */ '../views/infoShow.vue'),
    meta: {
      index:null,title: '乐腾出海用户信息收集',
      keepAlive: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    meta: {
      index:null,title: '乐腾出海'
    }
  },
  //以下是移民项目内容
  {
    path: '/index',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/cilinist/home.vue'),
    meta:{
      index:0,title:'首页',
      keepAlive: true
    }
  },
  {
    path: '/nation',
    name: 'Nation',
    component: () => import(/* webpackChunkName: "about" */ '../views/cilinist/nation.vue'),
    meta:{
      index:1,title:'移民国家',
      keepAlive: true
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkName: "about" */ '../views/cilinist/team.vue'),
    meta:{
      index:2,title:'团队介绍',
      keepAlive: true
    }
  },
  {
    path: '/custom',
    name: 'Custom',
    component: () => import(/* webpackChunkName: "about" */ '../views/cilinist/custom.vue'),
    meta:{
      index:3,title:'专业定制',
      keepAlive: true
    }
  },
  {
    path: '/nationInfo',
    name: 'NationInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/cilinist/nationInfo.vue'),
    meta:{
      index:null,title:'项目详情'
    }
  },
  {
    path: '/cropper',
    name: 'Cropper',
    component: () => import(/* webpackChunkName: "about" */ '../components/cropper.vue'),
    meta:{
      index:null,title:'图片上传'
    }
  },
  // 以下是问诊h5项目
  {
    path: '/inquiryHome',
    name: 'inquiryHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/inquiry/home.vue'),
    meta: {
      index:null,
      title: '医疗问诊'
    }
  },
  {
    path: '/inquiryLogin',
    name: 'inquiryLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/inquiry/login.vue'),
    meta: {
      index:null,
      title: '医疗问诊后台'
    }
  },
  {
    path: '/inquiryList',
    name: 'inquiryList',
    component: () => import(/* webpackChunkName: "about" */ '../views/inquiry/infoList.vue'),
    meta: {
      index:null,title: '医疗问诊用户信息收集',
      keepAlive: true
    }
  },
  {
    path: '/inquiryShow',
    name: 'inquiryShow',
    component: () => import(/* webpackChunkName: "about" */ '../views/inquiry/infoShow.vue'),
    meta: {
      index:null,
      title: '医疗问诊用户信息收集'
    }
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})


// 路由拦截
// router.beforeEach((to, from, next) => {
//   to.meta && (document.title = to.meta.title)

//   let arr = ['']

//   //超过三天未访问删除token
//   let oldDate = localStorage.getItem('oldDate')
//   let newDate = new Date().getTime()
//   if (oldDate && newDate - oldDate > 1000 * 60 * 60 * 24 * 3) {
//     localStorage.removeItem('wj_token')
//   }else if(to.path !== '/' && to.path !== '/home'){
//     localStorage.setItem('oldDate',newDate)
//   }

//   let token = localStorage.getItem('wj_token')
//   if (to.path !== '/login' && !token && to.path !== '/' && to.path !== '/home') {
//     next('/login')
//   }
//   next()
// })

export default router
