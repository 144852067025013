import axios from "axios"
const instance = axios.create({
    // baseURL: "http://192.168.1.32:8850",
    baseURL: "http://seainfo.lfmate.com:8850",
    
    timeout: 5*60*1000
})

//请求拦截器
instance.interceptors.request.use(config => {
    console.log("请求拦截器config为：", config)

    // 这个函数可以做一些发送请求之前的准备工作，例如把token添加到请求头
    // let token = localStorage.getItem("token");
    // if(token){
    // 把token放到请求头中
    // }
    if(config.timeout == "post"){
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    
    // config.headers.post['17token'] = 'BEFF1EC6ABB16CCD24CE51C97106C872'
    

    // let token = localStorage.getItem("token")
    // if (token) {
    //     config.headers = config.headers || {}
    //     config.headers['Authorization'] = token
    // }
    //需要返回confin才能成功发出请求
    return config
}, err => {
    return Promise.reject(err)
})


//响应拦截器
instance.interceptors.response.use(res => {
    // console.log("响应拦截器res为：", res)
    //需要返回confin才能成功发出请求
    return res
}, err => {
    return Promise.reject(err)
})


export default instance