<template>
  <div id="app">
    <!-- <router-view /> -->

    <!--页面返回不刷新-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    // this.isWeixin()

    // var ua = window.navigator.userAgent.toLowerCase();

    //   if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //     console.log('微信')
    //     document.getElementById("app").style.setProperty('font-size', '0.32rem');
    //     return true;
    //   } else {
    //     console.log('非微信',document.getElementById("app"))
    //     document.getElementById("app").style.setProperty('font-size', '0.16rem','important');
    //     return false;
    //   }

    let link = encodeURIComponent(window.location.href.split("?")[0]);
    console.log(unescape(link) == "http://localhost:8080/#/",unescape(link))
    let imgUrl = "";
    let desc = "";
    let title = "";
    if (unescape(link) == "http://seainfo.lfmate.com/#/home") {
      // 问卷调查
      imgUrl = "http://seainfo.lfmate.com/logo.jpg";
      desc = "乐腾团队专注出国移民，帮你量身定制移民方案";
      title = "乐腾出海-出国移民问卷调查";
      this.shareList(imgUrl, link, desc, title); // image 为分享的图片，必须是完整路径
    } else if (unescape(link) == "http://seainfo.lfmate.com/#/index") {
      // 移民H5
      imgUrl = "http://seainfo.lfmate.com/logo.jpg";
      desc = "乐腾互移(广州)咨询服务有限公司主营出国移民留学咨询业务，帮您量身定制全方位规划移民方案。";
      title = "乐腾出海-助力出国移民，为客户提供专业服务";
      this.shareList(imgUrl, link, desc, title); // image 为分享的图片，必须是完整路径
    } else if (unescape(link) == "http://seainfo.lfmate.com/#/inquiryHome") {
      // 医疗问卷H5
      imgUrl = "http://seainfo.lfmate.com/logo3.jpg";
      desc = "提供OMO中西医结合为世界各地人们提供诊断和治疗方案";
      title = "男性性功能疾病基本调查表";
      this.shareList(imgUrl, link, desc, title); // image 为分享的图片，必须是完整路径
    }
    // imgUrl = "http://seainfo.lfmate.com/logo.jpg";
    //   desc = "乐腾团队专注出国移民，帮你量身定制移民方案";
    //   title = "乐腾出海-出国移民问卷调查";
    // this.shareList(imgUrl, link, desc, title); // image 为分享的图片，必须是完整路径
  },
  methods: {
    isWeixin() {
      var ua = window.navigator.userAgent.toLowerCase();

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("微信");
        document
          .getElementById("app")
          .style.setProperty("font-size", "0.32rem");
        return true;
      } else {
        console.log("非微信", document.getElementById("app"));
        // document.getElementById("app").style.setProperty('font-size', '0.16rem','important');
        return false;
      }
    },
  },
};
</script>


<style lang="less">
* {
  margin: 0;
  padding: 0;
}
body {
  // -webkit-text-size-adjust: 100% !important;
  // text-size-adjust: 100% !important;
  // -moz-text-size-adjust: 100% !important;
}
#app {
  // font-size: 0.26rem !important;
}
// 移动端单位  rem   em
html {
  font-size: 110px !important;
  background-color: #ffffff;
}
</style>
